<template>
  <div class="meetDetail_container">
    <!--录制的四个选择块-->
    <div class="meetDetail_item">
      <div
        class="meetDetail_item_block"
        @click="doSomething(item)"
        v-for="(item, index) in config.buttonGroup"
        :key="index"
        :class="{ meetDetail_preview_disabled: item.disabled }"
      >
        <div class="meetDetail_preview_icon">
          <img :src="require(`@/assets/images/icon/${item.icon}`)" />
        </div>
        <p class="meetDetail_item_text">
          <span>{{ item.label }}</span>
          <span v-if="index == 0">(课件修改)</span>
        </p>
      </div>
    </div>
    <!--录制标题-->
    <p class="meetDetail_title">{{ data.courseware_title }}</p>
    <!-- <p class="meetDetail_title">{{ data.name }}</p> -->
    <!--录制时间-->
    <div class="meetDetail_main">
      <div class="meetDetail_time">
        <div class="meetDetail_time_block">
          <div class="meetDetail_time_clock">
            {{ $tools.getDate(data.start_time, "hh:mm") }}
          </div>
          <div class="meetDetail_time_date">
            {{ $tools.getDate(data.start_time, "yyyy年MM月dd日") }}
          </div>
        </div>
        <div class="meetDetail_time_split_block">
          <div class="meetDetail_time_line"></div>
          <div class="meetDetail_state">
            {{
              data.is_test
                ? $config.categary.meeting[0]
                : $tools.getMeetStatus(data)
            }}
          </div>
          <div class="meetDetail_time_line"></div>
        </div>
        <div class="meetDetail_time_block">
          <div class="meetDetail_time_clock">
            {{ $tools.getDate(data.end_time, "hh:mm") }}
          </div>
          <div class="meetDetail_time_date">
            {{ $tools.getDate(data.end_time, "yyyy年MM月dd日") }}
          </div>
        </div>
      </div>
      <div class="time_tips" v-if="data.status == 0">
        您可在创建后任意时间录制
      </div>
      <div class="doctorMeets_error_message" v-if="data.audit_status == 30">
        {{ data.feed_content }}
      </div>
    </div>
    <!--录制详细信息-->
    <div class="meetDetail_detail_block">
      <div
        class="meetDetail_detail_item"
        v-for="(item, index) in config.meetInfoList"
        :key="index"
      >
        <div class="meetDetail_detail_key">{{ item.label }}</div>
        <div
          class="meetDetail_detail_value"
          v-if="item.key == 'meeting_qrcode'"
          @click="goToQrCode()"
        >
          <div class="meetDetail_QR_code">
            <img :src="require('@/assets/images/icon/QR_code.png')" />
          </div>
          <el-icon>
            <ArrowRightBold />
          </el-icon>
        </div>
        <div class="meetDetail_detail_value" v-else>{{ getData(item) }}</div>
      </div>
    </div>
    <div class="meetDetail_group">
      <customButton @click="showPopop('pptxDownload')"
        ><div>幻灯下载</div></customButton
      >
      <customButton @click="watchMeeting()" v-if="data.status == 2"
        ><div>查看</div></customButton
      >
      <customButton @click="showPopop('originalHelp')" v-if="data.pdf_url"
        ><div>原文求助</div></customButton
      >
    </div>
    <div class="meetDetail_footer">
      <customButton @click="doBack()"><div>返回首页</div></customButton>
    </div>
    <!--原文求助-->
    <!-- <div class="meetDetail_help">
             <div class="meetDetail_help_text" @click="originalHelp">原文求助</div>
         </div> -->
    <opacityBg @click="showShareMeeting = false" v-show="showShareMeeting">
      <div class="shareMeeting_container">
        <img :src="require('@/assets/images/share.png')" alt="" />
      </div>
    </opacityBg>
    <opacityBg @click="qrcode.show = false" v-show="qrcode.show">
      <img :src="data.meeting_qrcode" @click.stop="" alt="" />
    </opacityBg>
    <opacityBg @click="original.showPopup = false" v-show="original.showPopup">
      <div class="meetDetail_help_block" @click.stop="">
        <p class="meetDetail_help_tips">{{ getPopopText() }}</p>
        <div class="meetDetail_help_email">
          <p>输入邮箱：</p>
          <el-input
            v-model="data.email"
            class="global_text_input meetDetail_help_input"
            placeholder="请输入您的邮箱"
          ></el-input>
        </div>
        <div class="meetDetail_help_buttonGroup">
          <customButton
            class="meetDetail_help_button"
            size="small"
            @click="commitEmail"
          >
            <div class="meetDetail_help_button_info">
              <span>确认</span>
            </div>
          </customButton>
          <customButton
            class="meetDetail_help_button"
            size="small"
            @click="cancelHelp"
          >
            <div class="meetDetail_help_button_info">
              <span>取消</span>
            </div>
          </customButton>
        </div>
      </div>
    </opacityBg>
    <!-- <div class="meetDetail_help_background">
             <div class="meetDetail_help_block">
                 <p class="meetDetail_help_tips">本平台不提供任何原文资源，仅采用百度学术文献互助平台的C2C模式，业内同道接到您的需求后会分享至您的邮箱。</p>
                 <div class="meetDetail_help_email">
                     <p>输入邮箱：</p>
                     <el-input v-model="data.email" placeholder="请输入您的邮箱"></el-input>
                 </div>
                 <div class="meetDetail_help_buttonGroup">
                 </div>
             </div>
         </div> -->

    <Popup
      :cfg="config.meetPopup"
      v-if="showPopup"
      @doClose="this.showPopup = false"
      @doSure="goToMeeting()"
    >
      <div class="meetDetail_popup" v-html="config.meetTip[meetingKey]"></div>
    </Popup>
    <Popup
      :cfg="config.myMeetPopup"
      v-if="showCoursewarePopup"
      @doClose="this.showCoursewarePopup = false"
      @doSure="goToMyMeeting()"
    >
      <div class="meetDetail_popup">您还未选题，请点击确认去选题！</div>
    </Popup>
  </div>
</template>
<script>
import { ElIcon, ElInput } from "element-plus";
import { ArrowRightBold } from "@element-plus/icons";
import customButton from "@/components/unit/customButton";
import opacityBg from "@/components/unit/opacityBg";
import Popup from "@/components/unit/popup";
import weixin from "@/common/weixin";
export default {
  name: "meetDetail",
  data() {
    return {
      state: 0,
      data: {
        email: "",
      },
      config: {
        meetPopup: {
          title: "提示",
        },
        myMeetPopup: {
          title: "提示",
          button: {
            cancel: false,
            sure: "确认",
          },
        },
        meetTip: {
          preview:
            "您的<span class='global_text_tag'>简历信息和照片</span>将用于视频制作和公开页面，请确认首页的<span class='global_text_tag'>简历信息和照片</span>正确？",
          formal:
            "即将开始正式录制。\n请确认是否已完成课件预览和修改？正式开始录制后将不能再修改课件。",
          qrcode:
            "即将开始正式录制。\n请确认是否已完成课件预览和修改？正式开始录制后将不能再修改课件。",
        },
        buttonGroup: [
          {
            label: "课件预览",
            disabled: true,
            params: {
              is_test: 1,
            },
            icon: "preview.png",
            doSomething: "isCanGoPreview",
          },
          {
            label: "开始录制",
            disabled: true,
            params: {
              is_test: 0,
            },
            icon: "startmeet.png",
            doSomething: "isCanGoMeeting",
          },
          {
            label: "协议确认",
            disabled: true,
            params: {},
            icon: "agreement.png",
            doSomething: "goToAgreement",
          },
          // {
          //   label: "录制分享",
          //   disabled: true,
          //   params: {},
          //   icon: "share.png",
          //   doSomething: "shareMeeting",
          // },
        ],
        meetInfoList: [
          {
            key: "meeting_number",
            label: "录制编号",
          },
          // {
          //   key: "meeting_qrcode",
          //   label: "录制二维码",
          // },
          {
            key: "doctor_hospital",
            // type: "info",
            label: "录制医院",
          },
          {
            key: "doctor_name",
            // type: "info",
            label: "录制专家",
          },
          {
            key: "courseware_title",
            label: "录制讲题",
          },
          // {
          //   key: "admin_phone",
          //   label: "录制申请人",
          // },
        ],
      },
      qrcode: {
        show: false,
      },
      original: {
        showPopup: false,
        doSome: "",
      },
      PopopText: {
        pptxDownload:
          "请输入您的邮箱，稍后我们将幻灯及提词通过邮件发送至您的邮箱，请注意查收",
        originalHelp:
          "本平台不提供任何原文资源，仅采用百度学术文献互助平台的C2C模式，业内同道接到您的需求后会分享至您的邮箱。",
      },
      showShareMeeting: false,
      showPopup: false,
      showCoursewarePopup: false,
      loading: false,
      meetingKey: "",
    };
  },
  created() {
    document.getElementsByTagName("title")[0].innerHTML = "解读详情";
    this.loadData();
  },
  components: {
    ArrowRightBold,
    ElIcon,
    ElInput,
    opacityBg,
    Popup,
    customButton,
  },
  methods: {
    loadData() {
      let url = this.$tools.getURL(this.$urls.admin.meetDetail, {
        id: this.$route.query.id,
      });
      this.$axios.get(url).then((res) => {
        this.data = res.data;
        console.log(this.data.meeting_url);
        if (!this.data.courseware_title) {
          this.showCoursewarePopup = true;
          return;
        }
        weixin.shareMessage({
          title: this.data.name,
          description: `主持专家：${this.$store.state.common.doctorInfo.name}\n${this.data.start_time}`,
          url: this.data.meeting_url,
        });
        this.data.email = this.$store.state.common.doctorInfo.email || "";
        this.initStatus();
      });
    },
    initStatus() {
      let isMeetingEnd = this.data.status == 2;
      this.config.buttonGroup[0].disabled = this.data.lock; // 如果锁定，则禁用
      this.config.buttonGroup[1].disabled =
        isMeetingEnd || this.data.custom_check == 0; // 录制结束 或 信息没有填完 则禁用
      this.config.buttonGroup[2].disabled =
        !(
          isMeetingEnd &&
          this.data.audit_status == this.$config.categary.audit.success
        ) || this.data.sign_name; // 如果录制没有结束或已签署则禁用
      console.log(this.config.buttonGroup[2].disabled);
      // this.config.buttonGroup[3].disabled = this.data.status == 2; // 一直启用
    },
    getData(item) {
      let data = !item.type ? this.data : this.$store.state.common.doctorInfo;
      return data[item.key] ?? "暂无";
    },
    doSomething(item) {
      if (item.disabled) {
        if (item.label == "开始录制" && this.data.custom_check == 0) {
          this.$tips.error({ text: "请先进入课件预览，完善课件" });
        }
        return;
      }
      this[item.doSomething](item.params);
    },
    isCanGoPreview() {
      this.meetingKey = "preview";
      this.showPopup = true;
    },
    isCanGoMeeting() {
      this.meetingKey = "formal";
      // if(this.data.lock) {
      //     this.goToMeeting()
      //     return;
      // }
      this.showPopup = true;
    },
    getMeetingParams() {
      let data = {
        is_test: this.meetingKey == "formal" ? 0 : 1,
      };
      return data;
    },
    goToMyMeeting() {
      this.$router.push("/myMeeting");
    },
    goToMeeting() {
      if (this.meetingKey == "qrcode") {
        this.showPopup = false;
        this.qrcode.show = true;
        return;
      }
      let params = this.getMeetingParams();
      let url = this.data.meeting_url.split("#")[1];
      this.$router.push({
        path: url,
        query: {
          ...params,
          role: this.$config.categary.role.doctor,
        },
      });
    },
    watchMeeting() {
      let url = this.data.meeting_url.split("#")[1];
      this.$router.push({
        path: url,
        query: {
          role: this.$config.categary.role.doctor,
          watch: 1,
        },
      });
    },
    goToAgreement(params) {
      console.log("params :>> ", params);
      this.$router.push({
        path: `/agreement/${this.data.id}/${this.data.doctor_id}`,
      });
    },
    shareMeeting(params) {
      this.showShareMeeting = true;
    },
    goToQrCode() {
      if (this.data.status == 2) return;
      if (this.data.custom_check == 0) {
        this.$tips.error({ text: "请先进入课件预览，完善课件" });
        return;
      }
      this.meetingKey = "qrcode";
      this.showPopup = true;
      // this.qrcode.show = true;
    },

    showPopop(doSome) {
      this.original.doSome = doSome;
      this.original.showPopup = true;
    },

    getPopopText() {
      return this.PopopText[this.original.doSome];
    },
    commitEmail() {
      if (!/[\w.-]+@[\w-]+\.[\w-]+/g.test(this.data.email)) {
        this.$tips.error({ text: "请输入正确的邮箱" });
        return false;
      }
      this[this.original.doSome]();
      // if(this.original.doSome ==="pptxDownload"){
      //     this.pptxDownload()
      // }
      // if(this.original.doSome ==="originalHelp"){
      //     this.originalHelp()
      // }
    },
    pptxDownload() {
      let id = this.data.id;
      let url = this.$tools.getURL(this.$urls.api.pptxDownload, {
        id: id,
        email: this.data.email,
        type: 1,
      });
      this.$axios
        .get(url)
        .then((res) => {
          this.$tips.success({ text: "发送成功" });
          this.original.showPopup = false;
        })
        .catch((err) => {
          this.original.showPopup = false;
        });
    },
    originalHelp() {
      let courseware_id = this.data.courseware_id;
      let url = this.$tools.getURL(this.$urls.api.sendEmail, {
        id: courseware_id,
        email: this.data.email,
        type: 1,
      });
      this.$axios
        .get(url)
        .then((res) => {
          this.$tips.success({ text: "求助成功" });
          this.original.showPopup = false;
        })
        .catch((err) => {
          this.original.showPopup = false;
        });
    },
    cancelHelp() {
      this.original.showPopup = false;
    },
    doBack() {
      this.$router.push("/doctorMeets");
    },
  },
};
</script>
<style>
.meetDetail_container {
  width: 100%;
  height: 100%;
  padding: 5.33vw;
  background: #f1f1f1;
  overflow: auto;
  display: flex;
  flex-direction: column;
}
.meetDetail_item {
  width: 100%;
  display: flex;
  justify-content: space-around;
}
.meetDetail_title {
  width: 100%;
  font-size: 4vw;
  font-weight: 400;
  margin: 4vw 0;
}
.meetDetail_item_block {
  width: 19.7vw;
  height: 19.7vw;
  border-radius: 1vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url("../../../assets/images/large.png");
  background-size: 100%;
}
.meetDetail_preview_disabled {
  width: 19.7vw;
  height: 19.7vw;
  border-radius: 1vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #b5b5b5;
}
.meetDetail_item_text {
  color: #fff;
  font-size: 3.6vw;
  font-weight: 400;
}
.meetDetail_item_text span {
  display: inline-block;
  text-align: center;
  width: 100%;
}
.meetDetail_preview_icon {
  width: 7.2vw;
  height: 7.2vw;
  margin-bottom: 2.4vw;
}
.meetDetail_preview_icon img {
  height: 100%;
  width: 100%;
}
.meetDetail_main {
  background: #fff;
  padding: 4.5vw;
}
.meetDetail_time {
  width: 100%;
  height: 20vw;
  border-radius: 1vw;

  display: flex;
  justify-content: space-between;
}
.meetDetail_time_block {
  width: 24vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.meetDetail_time_split_block {
  flex: 1 0 0;
  display: flex;
  align-items: center;
}
.meetDetail_time_line {
  flex: 1 0 0;
  margin: 0 1vw;
  border-bottom: 1px #b8b8b8 solid;
}
.meetDetail_state {
  height: 5.6vw;
  min-width: 13.6vw;
  padding: 0 1vw;
  font-size: 3.2vw;
  font-weight: 400;
  border-radius: 1vw;
  background: #eee;
  color: #ed6b00;
  display: flex;
  align-items: center;
  justify-content: center;
}
.meetDetail_time_clock {
  font-size: 6vw;
  font-weight: 400;
}
.meetDetail_time_date {
  margin: 1.8vw 0;
  font-size: 3.2vw;
  font-weight: 400;
}
.meetDetail_detail_block {
  background: #fff;
  width: 100%;
  padding: 0 3.2vw 4vw;
  margin-top: 4vw;
  /* height: 72.8vw; */
}
.meetDetail_detail_item {
  width: 100%;
  min-height: 11.2vw;
  border-bottom: 1px #b8b8b8 solid;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.meetDetail_detail_key {
  font-size: 3.6vw;
  font-weight: 400;
  color: #363636;
}
.meetDetail_detail_value {
  flex: 1 0 0;
  padding: 2vw 0 2vw 8vw;
  font-size: 3.6vw;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.meetDetail_QR_code {
  height: 7.2vw;
  width: 7.2vw;
}
.meetDetail_QR_code img {
  width: 100%;
  height: 100%;
}
.meetDetail_container .shareMeeting_container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.meetDetail_container .shareMeeting_container img {
  width: 100%;
}
.meetDetail_help {
  margin-top: 10vw;
  width: 100%;
  display: flex;
  justify-content: center;
}
.meetDetail_help_text {
  color: #0100c8;
  padding-bottom: 1vw;
  font-size: 4vw;
  border-bottom: #0100c8 1px solid;
}
.meetDetail_help_background {
  width: 100%;
  height: 100%;
  background: rgba(193, 193, 193, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.meetDetail_help_block {
  background: #fff;
  width: 60%;
  padding: 4vw;
  border-radius: 1vw;
}
.meetDetail_help_tips {
  line-height: 7vw;
}
.meetDetail_help_email {
  margin: 8vw 0 2vw 0;
  color: #0100c8;
}
.meetDetail_help_input {
  border: none;
  padding: 0;
}
.meetDetail_help_buttonGroup {
  width: 100%;
  height: 8vw;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.meetDetail_help_button {
  width: 20vw;
  height: 8vw;
  padding: 1vw;
  border-radius: 1vw;
}
.meetDetail_help_button_info {
  height: 100%;
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.meetDetail_group {
  width: 100%;
  /* height: 12vw; */
  display: flex;
  justify-content: space-around;
  margin-top: 8.4vw;
}
.meetDetail_popup {
  padding: 8vw;
  white-space: pre-wrap;
}
.time_tips {
  color: #ccc;
  font-size: 3.2vw;
}
.meetDetail_footer {
  flex: 1 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8.4vw;
}
.doctorMeets_error_message {
  color: red;
  margin-top: 4vw;
}
.meetDetail_container .popup_title {
  font-size: 4.6vw;
  margin-top: 2vw;
  font-weight: bold;
}
.meetDetail_container .meetDetail_popup {
  padding-top: 4vw;
}
.meetDetail_container .popup_button_group {
  font-size: 4.6vw;
}
/* .popup_button_cancel {
  background: #ccc;
  color: #fff;
}
.popup_button_sure {
  background: #0100c8;
  color: #fff;
} */
</style>
